import sliderImg01 from "../images/pop4.png";
import sliderImg02 from "../images/pop2.png";
import sliderImg03 from "../images/pop3.png";

export const sliderData = [
  {
    id: "01",
    title: "We have simple and delicious food for you",
    imgUrl: sliderImg01,
  },
  {
    id: "02",
    title: "We believe good food over great smile",
    imgUrl: sliderImg02,
  },
  {
    id: "03",
    title: "Meet, Eat and Enjoy the true test",
    imgUrl: sliderImg03,
  },
];
