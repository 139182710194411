import React from "react";
import "./download.css";

import appImg from "../../assets/images/app.png";

import { Container, Row, Col } from "reactstrap";

const Download = () => {
  return (
    <section>
      <Container className="app__container">
        <Row>
          <Col lg="6" md="6">
            <div className="app__img">
              <img src={appImg} alt="" />
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="app__content">
              <h5>Visit US</h5>
              <h2 className="mb-4">
                Never Feel Hungry!  
              </h2>
              <p>
              Falafel, hummus & other Syrian specialties served in a down-to-earth venue with a bohemian vibe.
              </p>

              <div className="app__btns d-flex align-items-center gap-5 mt-4">
                <button className="btn__apple d-flex align-items-center gap-3">
                 <a href="#">Call Us</a>
                </button>
 
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Download;
